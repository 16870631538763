import cx from "classnames";
import { i18n } from "i18next";
import { ToastOptions, toast } from "react-toastify";

export default ({
  message,
  options,
  uaResults,
  i18n,
}: {
  message: string;
  options: ToastOptions;
  uaResults: any;
  i18n: i18n;
}) => {
  toast(
    () => (
      <div
        className={cx({
          "font-['IRANYekanXVF']": i18n?.dir() === "rtl",
          "font-['Hubot-Sans']": i18n?.dir() === "ltr",
        })}
      >
        {message}
      </div>
    ),
    {
      ...options,
      style: uaResults.mobile
        ? {
            width: "calc(100% - 2rem)",
            margin: "auto",
            left: "1rem",
            bottom: "1rem",
          }
        : {},
      position: uaResults.mobile ? "bottom-center" : "bottom-right",
    },
  );
};
